import React, {Component} from 'react';
import {Calendar, DateRangePicker} from 'react-date-range';
import {LANGUAGES_CODE} from "utils/constants";
import moment from 'moment';

const CALENDAR_COMMON = {
    DaySelected: {
        background: '#1F4E66'
    },
    DayInRange: {
        background: '#1F4E66',
        color: '#fff'
    },
    DayPassive: {
        opacity: 0
    },
    PredefinedRangesItemActive: {
        background: '#1F4E66',
        color: 'white'
    },
    dayToday:{
        'span::after': {
            background: '#1F4E66',
            color: 'white'
        }
    },
    MonthPicker: {
            background: 'red',
    },
    PredefinedRanges: {marginLeft: 10, marginTop: 10}
};

const LINKED_CALENDAR_WIDTH = {
    Calendar: {
        width: 200
    }
};
const SINGLE_CALNDAR_WIDTH = {
    Calendar: {
        width: 280
    }
};

const LINKED_CALENDAR_THEME = Object.assign({}, CALENDAR_COMMON, LINKED_CALENDAR_WIDTH);
const SINGLE_CALENDAR_THEME = Object.assign({}, CALENDAR_COMMON, SINGLE_CALNDAR_WIDTH);

const SINGLE_CALENDAR_WIDTH = 280;
const DOUBLE_CALENDAR_WIDTH = 570;

const calendarPopupInstances = [];

export class DateFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            left: 0
        };

        this.onChange = this.onChange.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.openCalendar = this.openCalendar.bind(this);
        this.closeCalendar = this.closeCalendar.bind(this);
        this.getCalendarStyle = this.getCalendarStyle.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.buttonRef = React.createRef();

        window.addEventListener('resize', () => this.setPosition());

        calendarPopupInstances.push(this);
    }

    setPosition() {
        const buttonPosition = this.buttonRef.current?.getBoundingClientRect() || {top: 0, left: 0};

        let getLeft = buttonPosition.left - (SINGLE_CALENDAR_WIDTH / 4);
        let finalCalendarWidth = getLeft + SINGLE_CALENDAR_WIDTH;
        if (window.innerWidth < finalCalendarWidth)
            getLeft = window.innerWidth - SINGLE_CALENDAR_WIDTH - 10;

        this.setState({
            left: getLeft,
            top: buttonPosition.top - 16
        });
    }

    openCalendar(event) {
        this.setPosition();

        calendarPopupInstances.forEach((instance) => instance.closeCalendar());

        this.setState({
            isOpen: true
        });
    }

    closeCalendar() {
        this.setState({
            isOpen: false
        });

    }

    getValue() {
        let calendarDate = moment(this.refs.calendarInput.value, moment.localeData()._longDateFormat.L);
        return calendarDate;
    }

    onChange(value) {
        this.props.onChange(value);
        this.setState({
            isOpen: false
        });
    }

    onKeyDown(event) {
        if (event.keyCode === 27)
            this.closeCalendar(event);
    }

    getCalendarStyle() {
        let style = {};
        switch (this.props.style) {
            case "popup": {
                style = {
                    position: "fixed",
                    left: (this.state.left) + 'px',
                    top: this.state.top + "px",
                    zIndex: 99999,
                    width: SINGLE_CALENDAR_WIDTH + "px"
                };
                break;
            }
            case "inPopup": {
                style = {
                    position: "fixed",
                    zIndex: 1,
                    left: 'inherit',
                    top: "-20px",
                    width: SINGLE_CALENDAR_WIDTH + "px",
                    display: "table",
                    backgroundColor: "white"
                };
                break;
            }
            default: {
                style = {
                    width: SINGLE_CALENDAR_WIDTH + "px",
                    display: "table"
                }
            }
        }

        return style;
    }

    render() {
        const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
        const tzOffsetHours = Math.abs(tzOffset / (60 * 60 * 1000));

        const langDate = LANGUAGES_CODE[this.props.userLanguage];
        const value = moment(this.props.selectedValue)
            .set({hour: tzOffsetHours}).toDate().toLocaleDateString(langDate);
        const selectValue = moment.utc(this.props.selectedValue)
            .set({hour: tzOffsetHours}).toDate();

        const minDate = this.props.minDate ? moment(this.props.minDate).toDate() : selectValue;

        return (
            <div className="calendar-layout calendar-datepicker">
                <div
                    style={{position: 'relative'}}
                    tabIndex={this.props.tabIndex || '0'}
                    className={(this.props.isDisabled ? "disabled " : "") + "search-group calendar"}
                    ref={this.buttonRef}
                    onClick={this.openCalendar}
                    onKeyDown={this.onKeyDown}
                >
                    <input ref="calendarInput"
                           type="text"
                           aria-label={this.props.translate("calendar.calendar")}
                           className={(this.props.isDisabled ? "disabled " : "") + " col-md-12 calendar-input"}
                           value={value}
                           readOnly={true}
                    />
                    {this.props.icon ?
                        (<span className={this.props.icon}/>) :
                        (<span className="appicon calendar-icon"/>)
                    }
                    <span className={"clear-search d-none" + (this.props.selectedValue ? "show" : "")}/>
                </div>
                {this.state.isOpen &&
                    <div className="calendar-popup" style={this.getCalendarStyle()}>
                        <strong className="calendar-close color-grey"
                                title={this.props.translate("popUp.close")}
                                onClick={this.closeCalendar}>x</strong>
                        <Calendar style={{width: SINGLE_CALENDAR_WIDTH + 'px'}}
                                  date={selectValue}
                                  dateFormat={this.props.format}
                                  dateDisplayFormat={this.props.format}
                                  minDate={minDate}
                                  color="#1F4E66"
                                  onChange={this.onChange}
                                  theme={SINGLE_CALENDAR_THEME}
                        />
                        <button className="btn btn-primary float-right no-margin" onClick={this.closeCalendar}>
                            {this.props.translate("popUp.ok")}
                        </button>

                    </div>
                }
            </div>
        );
    }

}

export class LinkedDateFilters extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            left: 0,
            range: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'date'
            }
        };

        this.openCalendar = this.openCalendar.bind(this);
        this.closeCalendar = this.closeCalendar.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onChange({date}) {
        this.setState({
            range: {
                ...this.state.range,
                startDate: date.startDate,
                endDate: date.endDate
            }
        });

        this.props.dateSelect(this.props.column.searchValue, {date})
    }

    openCalendar(value, event) {
        let getLeft = event.target.getBoundingClientRect().left - (DOUBLE_CALENDAR_WIDTH / 4);
        let finalCalendarWidth = getLeft + DOUBLE_CALENDAR_WIDTH;

        if (window.innerWidth < finalCalendarWidth)
            getLeft = window.innerWidth - DOUBLE_CALENDAR_WIDTH - 10;

        this.setState({
            isOpen: true,
            left: getLeft
        });
    }

    closeCalendar(event) {
        // if (event.relatedTarget === null || event.relatedTarget.nodeName === 'INPUT') if(!event.relatedTarget && (document.activeElement.className.indexOf("rdr-") === -1)){
        this.setState({
            isOpen: false
        });

        console.log(event);
    }

    onKeyDown(event) {
        if (event.keyCode === 27)
            this.closeCalendar(event);
    }

    render() {
        let {column, removeDate} = this.props;
        let columnWidth = (column.width - 2 * 4.5);
        let inputWidth = columnWidth - 2;

        return (
            <td style={{width: columnWidth}} className="calendar-layout no-hover"
                id={this.props.setid} headers={this.props.headers}
                onKeyDown={this.onKeyDown}>
                <div style={{position: 'relative'}} tabIndex={0} className="search-group calendar"
                     role={"button"} aria-label={this.props.translate("calendar.calendar")}
                     onClick={this.openCalendar.bind(this, column.searchValue)}>
                    <input style={{width: inputWidth}}
                           className="calendar-input"
                           aria-label={this.props.translate("calendar.calendar")}
                           value={column.selectedValue}
                           readOnly={true}
                    />
                    <span className="appicon calendar-icon"/>
                    <span className={"clear-search " + (column.selectedValue ? "show" : "")}
                          onClick={removeDate.bind(null, column.searchValue)}/>
                </div>
                <div className="calendar-popup"
                     style={{
                         visibility: this.state.isOpen ? "visible" : "hidden",
                     }}>
                    <DateRangePicker
                        style={{width: DOUBLE_CALENDAR_WIDTH + 'px'}}
                        linkedCalendars={true}
                        ranges={[this.state.range]}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        direction="horizontal"
                        onChange={this.onChange}
                        rangeColors={["#1F4E66"]}
                        theme={LINKED_CALENDAR_THEME}
                    />
                    <strong className="calendar-close color-grey"
                            title={this.props.translate("popUp.close")}
                            onClick={this.closeCalendar}>x</strong>
                    <button className="btn btn-primary" onClick={this.closeCalendar}>
                        {this.props.translate("popUp.ok")}
                    </button>
                </div>
            </td>
        );
    }

}

export default DateFilter;