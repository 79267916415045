import React, {Component} from 'react';
import './Settings.scss';
import {Outlet} from 'react-router-dom';

export class Settings extends Component {

    render() {
        return (
            <main className="settings-page d-flex justify-content-center align-items-start">
               <div className={"w-50 mt-24 p-8 bg-white radius-4"}>
                <Outlet/>
               </div>
            </main>
        );
    }
}

export default Settings;
