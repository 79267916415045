import * as _ from "lodash";

export const customStyles = {
    valueContainer: (provided) => {
        const padding = "0 8px";
        return {
            ...provided,
            padding,
            borderColor: "#6F7687"

        };
    },

    control: (provided) => ({
        ...provided,
        borderColor: "#6F7687",
        color: "#133445",
        boxShadow: "none",
        ':hover': {
            borderColor: "#1F4E66",
        }
    }),

    placeholder: (styles) => ({
        ...styles,
        color: "#B9B9B9"
    }),

    multiValue: (provided, {data}) => ({
        ...provided,
        backgroundColor: data.valid === false ? "#ffebf5" : "#ebf5ff",
        border: "1px solid  #1F4E66"
    }),

    multiValueRemove: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "lightgrey",
        '&:hover': {
            backgroundColor: "transparent",
            color: "darkgrey"
        },
        borderLeft: "1px solid lightgrey"
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {...provided, opacity, transition
        };
    },

    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: "#1F4E66",
        backgroundColor: isSelected? "#FAE0CE" : isFocused ? "#FAE0CE" : "white",
        ':active':{
            backgroundColor: "#FAE0CE"
        },
        ':hover': {
            cursor: "pointer",
            backgroundColor: "#FAE0CE",
        }
    }),

    noOptionsMessage: (provided) => ({
        ...provided,
        color: "red",
        textAlign: "left"
    }),

    menu: (provided) => ({
        ...provided,
        minHeight: "30px",
        maxHeight: "140px",
        lineHeight: "20px",
        marginTop: "0px",
        border: "1px solid #133445",
        boxShadow: "none",
    }),
    primary25: 'hotpink',
};

let menuStyle = _.cloneDeep(customStyles);

menuStyle.menu = (provided) => {
    return {
        ...provided,
        marginTop: "0px",
        border: "1px solid #6F7687",
    }
}

export let autoMenuStyle = menuStyle;

let advancedHistory = _.cloneDeep(customStyles);

advancedHistory.menu = (provided) => {
    return {
        ...provided,
        marginTop: "0px",
        border: "1px solid #133445",
        minWidth: "250px",
        minHeight: "30px",
        position: "absolute",
        top: "inherit",
        zIndex: "2",
        cursor: "pointer",
        boxShadow: "none",

    }
}

export let advancedHistoryStyle = advancedHistory;

let fixedStyle = _.cloneDeep(customStyles);

fixedStyle.menu = (provided) => {
    return {
        marginTop: "0px",
        border: "1px solid #133445",
        backgroundColor: "white",
        minWidth: "390px",
        maxWidth: "680px",
        position: "fixed",
        top: "inherit",
        zIndex: "2"
    }
}
fixedStyle.menuList = (provided) => ({
    ...provided,
    minHeight: "30px",
    maxHeight: "200px",
    lineHeight: "20px",
    padding: "0"
})

export let fixedMenuStyle = fixedStyle;
