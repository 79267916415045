import React, {Component} from 'react';
import translate from '../translations/translations.wrapper.jsx';
import ConfigurationWrapper from '../configuration/ConfigurationWrapper.component';
import * as Api from '../../utils/api/api';
import {handleError, insufficientRightsError} from '../../utils/errorHandle.function';
import Confirm from '../popup/ConfirmActionPopup.component';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import {EditDeleteButtons, SaveCancelButtons} from 'utils/listActionButtons.component';

import "./roles.scss";
import {userHasAccessRights} from "../../utils/accessRights.function";
import {canViewConfiguration} from "../menu/Menu.component";

export class Roles extends ConfigurationWrapper {

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            showNewRow: false,
            confirmDelete: false,
            warningMessage: "",
            toDelete: null
        };

        this.addRow = this.addRow.bind(this);
        this.cancelAdd = this.cancelAdd.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.getData = this.getData.bind(this);
        this.updateRow = this.updateRow.bind(this);
    }

    componentDidMount(props) {
        if (!userHasAccessRights(this.props.userData.userRoles, this.props.location) || !canViewConfiguration(this.props.userData.userRoles)) {
            handleError(insufficientRightsError);
        }

        super.componentDidMount(props);
    }

    addRow(name) {
        Api.addRuleConfigurationRoles(name)
            .then(() => {
                this.getData();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    cancelAdd() {
        this.setState({
            showNewRow: false
        });
    }

    confirmDelete() {
        Api.deleteRuleConfigurationRoles(this.state.toDelete, true).then(result => {
            this.getData();
        }, error => {
                handleError(error);
        });
        this.setState({confirmDelete: false})
    }

    deleteRow(id) {
        this.setState({
            confirmDelete: true,
            toDelete: id
        })
    }

    getData() {
        Api.getRuleConfigurationRoles().then(result => {
            this.setState({
                roles: result,
                showNewRow: false
            });
        });

    }

    updateRow(data) {
        Api.updateRuleConfigurationRoles(data).then(result => {
            this.getData();
        });
    }


    buildContent() {
        let readOnly = this.props.userRoles.systemViewer && !this.props.userRoles.systemAdministrator;

        let roles = this.state.roles.map((role, index) => {
            return (<RoleRow key={role.roleId} role={role}
                             readOnly={readOnly}
                             updateRow={this.updateRow}
                             deleteRow={this.deleteRow}
                             translate={this.props.translate}/>)
        });

        return <div className="roles">
            {this.state.confirmDelete &&
            <Confirm translate={this.props.translate}
                     message={this.props.translate("roles.confirmDelete")}
                     handleAction={this.confirmDelete}
                     closeCallback={() => {
                         this.setState({confirmDelete: false})
                     }}/>}

            <h3 className="no-border d-flex align-items-center pt-4">{this.props.translate("roles.header")}
                <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                      title={this.props.translate("roles.headerInfo")}/>
            </h3>

            <div className="actions-list row mx-0">
                <div className="col-md-6">
                    <div className="row header-row mx-0">
                        <div className="col-md-9">
                            {this.props.translate("roles.approverRole")}
                        </div>
                        <div className="col-md-3 text-center">
                            {this.props.translate("roles.actions")}
                        </div>
                    </div>
                    <div className="main-panel">
                        {roles}
                        {this.state.showNewRow &&
                        <NewRow addRow={this.addRow} cancelAdd={this.cancelAdd} translate={this.props.translate}/>
                        }

                        {!readOnly &&
                        <div className="row mx-0">
                            <div className="col-md-9"/>
                            <div className="col-md-3 text-center">
                                <span className="vismaicon vismaicon-add-circle vismaicon-dynamic"
                                   title={this.props.translate("roles.add")}
                                   onClick={() => {
                                       this.setState({showNewRow: true})
                                   }}/>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

const withTranslations = translate(Roles);
const mapStateToProps = function (store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        userRoles: reduxSelectors.getUsersRoles(store),
        userData: reduxSelectors.getUsersData(store)
    };
};

const connected = connect(mapStateToProps, {})(withTranslations);
export default connected;

class RoleRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            roleName: props.role.name
        };

        this.changeRoleName = this.changeRoleName.bind(this);
        this.delete = this.delete.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.save = this.save.bind(this);

    }

    changeRoleName(event) {
        this.setState({
            roleName: event.target.value
        });
    }

    delete() {
        this.props.deleteRow(this.props.role.roleId);
    }

    enterEditMode() {
        this.setState({
            editMode: true
        });
    }

    exitEditMode() {
        this.setState({
            editMode: false
        });
    }

    save() {
        let role = {
            name: this.state.roleName,
            idRole: this.props.role.roleId
        };
        this.props.updateRow(role);

        this.setState({
            editMode: false
        });
    }

    render() {
        if (this.state.editMode) {
            return (
                <div className="row">
                    <div className="col-md-9 py-3">
                        <input type="text" value={this.state.roleName} onChange={this.changeRoleName}/>
                    </div>

                    <div className="col-md-3 text-center">
                        <SaveCancelButtons
                            saveCallback={this.save}
                            cancelCallback={this.exitEditMode}
                            translate={this.props.translate}
                        />
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="row">
                    <div className=" col-md-9 py-3">{this.props.role.name}</div>

                    <div className="col-md-3 text-center">
                        <EditDeleteButtons
                            disabled={this.props.editMode || this.props.readOnly}
                            editCallback={this.enterEditMode}
                            deleteCallback={this.delete}
                            translate={this.props.translate}
                        />
                    </div>

                </div>
            )
        }
    }
}

const NewRow = (props) => {
    let newName = null;
    let save = function () {
        if (newName)
            props.addRow(newName);
    };
    let nameValueChanged = function (event) {
        newName = event.target.value;
    };
    let cancel = function () {
        props.cancelAdd();
    };
    return (
        <div className="row">
            <div className="col-md-9 py-3">
                <input type="text" onChange={nameValueChanged}/>
            </div>

            <div className="col-md-3 text-center">
                <SaveCancelButtons
                    saveCallback={save}
                    cancelCallback={cancel}
                    translate={props.translate}
                />
            </div>
        </div>
    )
};