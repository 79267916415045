import React from "react";
import {MODAL_CONTENT_TAB_INDEX} from "utils/constants";
import OKCancelButtons from "components/modals/OKCancelButtons";
import {UserSearch} from "components/input/UserSearch.component";
import {VisibleToEverybody} from "components/popup/PopUp.component";
import Comment from "components/input/Comment.component";
import {requestReview} from "components/modals/modal.actions";
import {useDispatch} from "react-redux";
import {RequestReviewActionData} from "types/modals";
import {getTaskUserDescription} from "utils/valueFormatter.function";


const RequestReview = ({more, translate, closeModal, loopFocus, errorCallback}: any): React.ReactElement => {

    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [recipient, setRecipient] = React.useState<string>(null);
    const [comment, setComment] = React.useState<string>(null);

    const recipientInput = React.createRef<UserSearch>();
    const dispatch = useDispatch();
    const [actionRunning, setActionRunning] = React.useState(false);


    const sendForReviewAction = () => {
        if (comment && recipient) {
            setActionRunning(true);
            const currentTask = more?.tasks[0];

            const data: RequestReviewActionData = {
                key: currentTask.key,
                comment: comment,
                recipient: recipient,
                taskDescription: getTaskUserDescription(currentTask)
            };
            if (currentTask)
                dispatch(requestReview(data,
                    forwardSuccessCallback,
                    forwardErrorCallback));
        }
    }

    const forwardSuccessCallback = () => {
        closeModal();
    }
    const forwardErrorCallback = () => {
        setActionRunning(false);
        errorCallback();
    }


    const okEnabled: boolean = comment && recipient && !actionRunning;
    return (
        <div>
            <h4 className="modal-title">{more?.title || translate("popUp.sendforreview.title")}</h4>
            <div className="modal-body ast_request_review">

                <div className="margin-bottom-12">
                    <span className="text-danger">* </span>
                    {translate("popUp.sendforreview.reviewer")}
                </div>
                <UserSearch ref={recipientInput}
                            propagateValue={value => setRecipient(value)}
                            tabindex={MODAL_CONTENT_TAB_INDEX}
                            refresh={refresh}
                            taskId={more?.tasks[0]?.key}
                            ariaLabel={translate("popUp.sendforreview.reviewer")}
                            placeholder={translate("userSearch.placeholder")}
                            value={recipient}
                            translate={translate}
                            releaseFocus={() => {
                                setRefresh(false)
                            }}/>


                <label htmlFor="comment" className={"mt-3 d-flex"}>
                    <span className="text-danger me-1">*</span> {translate("popUp.sendforreview.commentLabel")}
                    <span className={"ms-1"}> - </span>
                    <span className={"ms-2"}>
                            <VisibleToEverybody translate={translate}/>
                        </span>
                </label>

                <Comment inputHint={translate("popUp.sendforreview.commentHint")}
                         translate={translate}
                         tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                         id="comment"
                         propagateValue={value => setComment(value)}
                         value={comment}
                />


            </div>
            <OKCancelButtons
                tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                okEnabled={okEnabled}
                okCallback={sendForReviewAction}
                okText={translate("popUp.sendforreview.sendForReview")}
                cancelEnabled={true}
                cancelCallback={closeModal}
                cancelText={translate("popUp.close")}
                loopFocus={loopFocus}
            />
        </div>
    );
};

export default RequestReview;