import translate from "components/translations/translations.wrapper";
import noInvoice from "components/taskdetails-remake/icons/Pictogram-selected-invoice.svg";
import { BypassCIRSkeletonActions } from "components/taskdetails-remake/panels/attachments/BypassCIR.Skeleton";

export const AttachmentsPanelInWaiting = ({translate}) => {

    return (
        <div className={"d-flex flex-column detached w-100 h-100"}>
            <div className="d-flex flex-row py-24 px-3 mb-3">
                <div className="d-flex mr-auto font-16 semibold attachment-header__title__div">
                    {translate("taskDetail.waitingForDocument.header")}
                </div>
            </div>
            <BypassCIRSkeletonActions />
            <div className="d-flex flex-column h-100 flex-grow-1 rounded-4 bg-natural-white icon-128 p-64 m-3 mt-0">
                <img src={new URL(noInvoice, window.location.origin).href} alt={translate("taskDetail.waitingForDocument.title")} />
                <div className="pt-32 font-16 semibold">
                    {translate("taskDetail.waitingForDocument.title")}</div>
                <div>{translate("taskDetail.waitingForDocument.subtitle")}</div>
            </div>
        </div>

    );
};

export default translate(AttachmentsPanelInWaiting);