import React from "react";
import translate from "../../../translations/translations.wrapper.jsx";
import "./datatooltip.scss";
import "./draftActionlessComments.scss";
import { useSelector } from "react-redux";
import * as reduxSelectors from "../../../store/application.reducers";
import CommentsList from "components/taskdetails-remake/panels/draftcomments/CommentsList.component";
import CommentsInput from "components/taskdetails-remake/panels/draftcomments/CommentsInput.component";
import { DraftCommentsPanelProps } from "types/comments";
import CommentsProfile from "components/taskdetails-remake/panels/draftcomments/CommentsProfile.component";
import CommentsValidation from "components/taskdetails-remake/panels/draftcomments/CommentsValidation.component";
import { COMMENT_SAVING_STATUS, COMMENTS_INPUT_MAX_LENGTH } from "utils/constants";

const CommentsPanel: React.FC<DraftCommentsPanelProps> = (props) => {

    const comment = useSelector(reduxSelectors.getTaskDraftComment);
    const currentUser = useSelector(reduxSelectors.getUsersLoggedInData);
    const validationError = comment?.length > COMMENTS_INPUT_MAX_LENGTH ? " validation-error" : "";
    const savingStatus = useSelector(reduxSelectors.getTaskDraftCommentsSavingStatus);
    const commentsCount = useSelector(reduxSelectors.getTaskDraftCommentsCount);
    const isLoading = useSelector(reduxSelectors.getTaskDraftCommentsLoadingStatus);

    const isAddCommentDisabled = () => {
        return !comment ||
            savingStatus === COMMENT_SAVING_STATUS.START ||
            savingStatus === COMMENT_SAVING_STATUS.SAVING ||
            comment?.length > 500 ||
            comment?.length === 0;
    };

    const currentCommentsCount = () => {
        if(props.allowExtraComments)
            return isLoading ? 0 : commentsCount;
        return props.initialCommentsCount ? props.initialCommentsCount : 0;
    };

    return (
        <div className="d-flex align-items-stretch flex-column h-100" data-cy="taskDetail.commentsPanel">
            <div className="grid-panel-header">
                <strong>
                    {props.translate("taskDetail.comments")}
                    <span
                        className="mx-2 badge badge-default"
                        data-cy="taskDetail.comments.number"> {currentCommentsCount() <= 999 ? currentCommentsCount() : "999+"}</span>
                </strong>
            </div>

            <CommentsList readOnly={props.readOnly} />
            {!(props.readOnly) &&
                <div className=" d-flex flex-column mt-auto py-16 pr-24 pl-0 border-box-sizing new-comment-area">
                    <div className="d-flex flex-row">
                        <CommentsProfile
                            connectUserId={currentUser.connectUserId}
                            userName={currentUser.fullName} />
                        <div
                            className="col-md-12 pr-40 align-self-stretch">
                            <div
                                className={"d-flex w-100 rounded-4 input-border p-8" + validationError + (props.offline ? " disabled" : "")}>
                                <CommentsInput id="comments"
                                               value={comment ? comment : ""}
                                               placeholder={props.translate("taskDetail.addCommentPlaceholder")}
                                               onChange={props.onChange}
                                               disabled={props.offline}
                                />
                                {props.allowExtraComments && <div className="d-flex align-self-end ml-8"
                                     data-testid={"addBtn"}>
                                    <button type="button"
                                            className="d-flex flex-column btn btn-icon  btn-send"
                                            disabled={isAddCommentDisabled()}
                                            onClick={props.onSaveComment}
                                            data-cy="taskDetail.comments.saveButton">
                                        <span className={"vismaicon vismaicon-paperplane-dyn "} />
                                        {props.translate("taskDetail.sendNewComment")}
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <CommentsValidation offline={props.offline}/>
                </div>
            }
        </div>
    );
};

const withTranslations = translate(CommentsPanel);

export default withTranslations;