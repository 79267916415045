import translate from "../../../translations/translations.wrapper.jsx";
import { formatDateTime } from "utils/valueFormatter.function";
import { CommentsListProps, Comment } from "types/comments";
import React, {useEffect} from "react";
import CommentsProfile from "components/taskdetails-remake/panels/draftcomments/CommentsProfile.component";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import * as reduxSelectors from "components/store/application.reducers";

export const CommentsList: React.FC<CommentsListProps> = (props) => {

    const isLoading = useSelector(reduxSelectors.getTaskDraftCommentsLoadingStatus);
    const comments: Array<Comment> = useSelector(reduxSelectors.getTaskDraftComments);
    const allowExtraComments = useSelector(reduxSelectors.getAllowExtraComments);

    const commentsListRef = React.createRef<HTMLDivElement>();

    const mapComment = function(comment: Comment, index: number) {
        const actionDescription = [1, 2, 3, 5, 9, 22, 55];
        return (
            <div className="d-flex py-16 pr-24 pl-0 border-box-sizing" key={`${comment.nodeId}-${index}`}
                 data-cy="taskDetail.comments.listitem">
                <CommentsProfile connectUserId={comment.connectUserId}
                                    userName={comment.userName}
                                    externalApplicationName={comment.externalApplicationName}
                                    eventType={comment.eventType}>

                </CommentsProfile>
                <div className="new-comments-list-item-body flex-grow-1 flex-shrink-1 ml-16">
                    <div className="d-flex justify-content-between">
                            <span className="d-flex flex-grow-1 flex-shrink-1 font-14 text-default semibold">
                                {comment.userName}
                            </span>
                        <span className="d-flex flex-shrink-0 flex-grow-0 flex-nowrap font-14 text-disabled">
                                {formatDateTime(comment.createdDate, "")}
                            </span>
                    </div>
                    {actionDescription.includes(comment.eventType) &&
                        <div className="font-12 text-disabled">
                            {props.translate(`taskDetail.commentsAction.${comment.eventType}`)}
                        </div>}
                    <div className="mt-2 w-100 font-14 text-default whitespace-pre-line break-word"
                         data-cy="taskDetail.comments.listitem.content">
                        {comment.content}
                    </div>
                </div>
            </div>
        );

    };


    const commentsList = [
        ...comments,
    ].reverse().map((comment, index) => {
        if(allowExtraComments || !comment.current)
            return mapComment(comment, index);
    });


    useEffect(() => {
        if(commentsListRef.current)
            commentsListRef.current.scrollTop = commentsListRef.current.scrollHeight;
    }, [commentsList]);

    const renderLoading = function() {
        return <div>
            <div className="d-flex mr-24 my-16 flex-row">
                <Skeleton className="new-comment-image-loading-skeleton rounded-4" />
                <div className="d-flex flex-column w-100 ml-16">
                    <Skeleton className="new-comment-content-loading-skeleton rounded-4 w-50 " />
                    <Skeleton className="new-comment-content-loading-skeleton rounded-4 mt-10" />
                </div>
            </div>
            <div className="d-flex mr-24 my-16 flex-row">
                <Skeleton className="new-comment-image-loading-skeleton rounded-4" />
                <div className="d-flex flex-column w-100 ml-16">
                    <Skeleton className="new-comment-content-loading-skeleton rounded-4 w-50" />
                    <Skeleton className="new-comment-content-loading-skeleton rounded-4 mt-10" />
                </div>
            </div>
            <div className="d-flex mr-24 mt-16 flex-row">
                <Skeleton className="new-comment-image-loading-skeleton rounded-4" />
                <div className="d-flex flex-column w-100 ml-16">
                    <Skeleton className="new-comment-content-loading-skeleton rounded-4 w-50" />
                    <Skeleton className="new-comment-content-loading-skeleton rounded-4 mt-10" />
                </div>
            </div>
        </div>;
    };

    return (
        <div className="new-comments-list d-flex flex-column flex-grow-1" ref={commentsListRef}>
            {isLoading ? renderLoading() : commentsList}
            {comments.length === 0 && !isLoading &&
                <div
                    className="d-flex flex-column justify-content-center h-100">
                    <div className="visma-pictogram vismaicon-comment-dyn d-flex justify-content-center disabled"></div>
                    <div className="d-flex justify-content-center font-16 semibold mt-4">
                        {props.readOnly ?
                            props.translate("taskDetail.noCommentsAdded") :
                            props.translate("taskDetail.emptyComment")}
                    </div>
                    {!props.readOnly && <div className="d-flex justify-content-center mt-2">{props.translate("taskDetail.emptyCommentMoreInfo")}</div>}
                </div>}
        </div>
    );
};

const withTranslations = translate(CommentsList);
export default withTranslations;
