import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DOCUMENT_TYPE, TABLE_COLUMNS_TYPES, WORKFLOW_PROCESS_STATUS} from '../../utils/constants';
import SearchGroupInput from '../input/SearchGroupInput.component';
import {LinkedDateFilters} from '../input/CalendarInput.component';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import moment from "moment";

const COLUMN_LEFT_RIGHT_MARGIN = 2 * 4.5;

export class TableSearchRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyDocumentTypes: [],
            processStates: [],
            companyId: props.companyId
        }
        this.dateSelect = this.dateSelect.bind(this);
        this.defaultSearch = this.defaultSearch.bind(this);
        this.removeDate = this.removeDate.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.clearAllFilters = this.clearAllFilters.bind(this);
        this.selectSearch = this.selectSearch.bind(this);
        this.getDocTypeTile = this.getDocTypeTile.bind(this);
        this.getStatusTitle = this.getStatusTitle.bind(this);
        this.getRoleTitle = this.getRoleTitle.bind(this);
        this.toggleCombo = this.toggleCombo.bind(this);
    }

    /*
     * we need to activate/deactivate the advanced filter at company context changes
     * */
    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.setState({
                companyId: this.props.companyId
            });
            this.loadFiltersData();
        }
    }

    removeDate(value, event) {
        event.stopPropagation();
        event.preventDefault();
        let newQuery = {};
        newQuery[value] = -1;
        this.props.onSearch(newQuery, value);
    }

    dateSelect(value, {date}) {

        let newQuery = {};
        let startDate = moment(date.startDate).format("YYYY-MM-DD");
        let endDate = moment(date.endDate).format("YYYY-MM-DD");
        //interval dates need special formatting

        if (startDate !== endDate) {
            newQuery[value] = startDate + "->" + endDate;
        } else {
            newQuery[value] = startDate;
        }
        this.props.onSearch(newQuery, value);
    }

    defaultSearch(value, event) {
        let newQuery = {};
        const regex_emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]+/gu;
        newQuery[value] = event.target.value !== "" ? event.target.value : -1;
        if (!regex_emoji.test(event.target.value))
            this.props.onSearch(newQuery, value);
    };

    selectSearch(property, value) {
        let newQuery = {};
        newQuery[property] = value;
        this.props.onSearch(newQuery, property);
    }

    clearSearch(value, event) {
        let newQuery = {};
        newQuery[value] = -1;
        this.props.onSearch(newQuery, value);
    }

    clearAllFilters(event) {
        this.props.onClearAllFilters();
    }

    getDocTypeTile(column) {
        if (column.selectedValue === -1 || column.selectedValue === "") {
            return this.props.translate("myHistory.all");
        }
        return this.props.translate("documentType." + DOCUMENT_TYPE.asString(parseInt(column.selectedValue)));
    }

    getStatusTitle(column) {
        if (column.selectedValue === -1 || column.selectedValue === "") {
            return this.props.translate("myHistory.all");
        }
        return this.props.translate("workflowProcessStatus." + WORKFLOW_PROCESS_STATUS.asString(parseInt(column.selectedValue)));
    }

    getRoleTitle(column) {
        if (column.selectedValue === -1 || column.selectedValue === "") {
            return this.props.translate("myHistory.all");
        }
        let roles = this.props.selectors[column.type];
        let response = "";
        roles.forEach(role => {
            const columnSelected = parseInt(column.selectedValue);
            if (role.actorTypeCode === (isNaN(columnSelected) ? column.selectedValue : columnSelected)) {
                response = role.actorTypeName;
                return response;
            }
        });
        return response;
    }

    toggleCombo(isOpen, event) {


        if (isOpen) {
            let left = event.originalEvent.currentTarget.getBoundingClientRect().left;
            let top = event.originalEvent.currentTarget.getBoundingClientRect().top + 36;
            let comboMenu = event.originalEvent.currentTarget.nextElementSibling;

            let comboOptionsListStyle = "position:fixed;top:" + top + "px;left:" + left + "px;z-index:9999";
            if (comboMenu)
                comboMenu.setAttribute("style", comboOptionsListStyle);
        }
    }

    render() {
        let cells = this.props.columns.map((column, index) => {
            let columnWidth = column.width;

            switch (column.type) {
                case TABLE_COLUMNS_TYPES.TEXT:
                case TABLE_COLUMNS_TYPES.AMOUNT:
                case TABLE_COLUMNS_TYPES.NUMBER: {
                    return (
                        <td key={index} style={{width: column.width - COLUMN_LEFT_RIGHT_MARGIN}}
                            headers={column.searchValue + "-" + index}
                            className="no-hover"
                            id={column.searchValue + "_search"}>
                            <SearchGroupInput value={column.selectedValue}
                                              ariaLabel={column.header?.label}
                                              width={column.width - COLUMN_LEFT_RIGHT_MARGIN}
                                              onInputChange={this.defaultSearch.bind(null, column.searchValue)}
                                              onClearInput={this.clearSearch.bind(null, column.searchValue)}
                                              translate={this.props.translate}
                            />
                        </td>);
                }
                case TABLE_COLUMNS_TYPES.CHECKBOX: {
                    return (
                        <td key={index} style={{textAlign: "center", width: columnWidth - COLUMN_LEFT_RIGHT_MARGIN}}
                            headers={column.searchValue + "-" + index}
                            className="no-hover"
                            id={column.searchValue + "_search"}>
                            <div className={"checkbox mx-0 ps-2 pe-3"}>
                                <input type={column.type}
                                       id={"headerChk"}
                                       checked={this.props.allRowsSelected}
                                       onChange={this.props.selectAll}/>
                                <label htmlFor={"headerChk"}/>
                            </div>
                        </td>);
                }
                case TABLE_COLUMNS_TYPES.SELECT: {
                    let documentTypes = [];
                    if (this.props.selectors['documentType']) {
                        documentTypes = this.props.selectors['documentType'].map((type, index) => {
                            return (<Dropdown.Item key={index} value={type} type={type}
                                                   eventKey={type}>
                                {this.props.translate("documentType." + DOCUMENT_TYPE.asString(type))}
                            </Dropdown.Item>);
                        });
                    }
                    return (
                        <td key={index} id={column.searchValue + "_search"} headers={column.searchValue + "-" + index}
                        className={"no-hover"}>
                            <DropdownButton title={this.getDocTypeTile(column)}
                                            id="documentTypeSelect"
                                            bsPrefix="btn btn-default dropdown-toggle text-start"
                                            className="dropdown-toggle text-start no-hover"
                                            style={{
                                                minWidth: columnWidth - COLUMN_LEFT_RIGHT_MARGIN,
                                                width: columnWidth - COLUMN_LEFT_RIGHT_MARGIN
                                            }}
                                            renderMenuOnMount="true"
                                            ref="drop"
                                            onToggle={this.toggleCombo}
                                            onSelect={this.selectSearch.bind(this, column.searchValue)}>
                                <Dropdown.Item key={-1} value={-1} eventKey={""}>
                                    {this.props.translate("myHistory.all")}
                                </Dropdown.Item>
                                {documentTypes}
                            </DropdownButton>
                        </td>
                    );
                }
                case TABLE_COLUMNS_TYPES.ROLE_SELECT: {
                    let getcounters = [];
                    if (this.props.selectors[column.type]) {
                        getcounters = this.props.selectors[column.type].map((type, index) => {
                            return (<Dropdown.Item key={index} value={type.actorTypeCode} type={type}
                                                   eventKey={type.actorTypeCode}>
                                {type.actorTypeName}
                            </Dropdown.Item>);
                        });
                    }
                    return (
                        <td key={index} id={column.searchValue + "_search"} headers={column.searchValue + "-" + index}
                        className={"no-hover"}>
                            <DropdownButton id="RoleSelect" key={index}
                                            style={{
                                                minWidth: columnWidth - COLUMN_LEFT_RIGHT_MARGIN,
                                                width: columnWidth - COLUMN_LEFT_RIGHT_MARGIN
                                            }}
                                            bsPrefix="btn btn-default dropdown-toggle text-start"
                                            className="dropdown-toggle text-start"
                                            renderMenuOnMount="true"
                                            title={this.getRoleTitle(column)}
                                            onToggle={this.toggleCombo}
                                            onSelect={this.selectSearch.bind(this, column.searchValue)}>
                                <Dropdown.Item key={-1} value={-1} eventKey={""}>
                                    {this.props.translate("myHistory.all")}
                                </Dropdown.Item>
                                {getcounters}
                            </DropdownButton></td>);
                }
                case TABLE_COLUMNS_TYPES.STATUS: {
                    let getcounters = [];
                    if (this.props.selectors[column.type]) {
                        getcounters = this.props.selectors[column.type].map((type, index) => {
                            return (
                                <Dropdown.Item key={index} value={type.statusCode} type={type}
                                               role="menuitem"
                                               eventKey={type.statusCode}>
                                    {type.status}
                                </Dropdown.Item>);
                        });
                    }
                    return (
                        <td key={index} id={column.searchValue + "_search"} headers={column.searchValue + "-" + index}
                        className={"no-hover"}>
                            <DropdownButton id="StatusSelect" key={index}
                                            style={{
                                                minWidth: columnWidth - COLUMN_LEFT_RIGHT_MARGIN,
                                                width: columnWidth - COLUMN_LEFT_RIGHT_MARGIN
                                            }}
                                            role="menu"
                                            bsPrefix="btn btn-default dropdown-toggle text-start"
                                            className="dropdown-toggle text-start"
                                            renderMenuOnMount="true"
                                            title={this.getStatusTitle(column)}
                                            onToggle={this.toggleCombo}
                                            onSelect={this.selectSearch.bind(this, column.searchValue)}>
                                <Dropdown.Item key={-1} value={-1} eventKey={-1}>
                                    {this.props.translate("myHistory.all")}
                                </Dropdown.Item>
                                {getcounters}
                            </DropdownButton></td>);
                }
                case TABLE_COLUMNS_TYPES.DATE_FILTER: {
                    return (
                        <LinkedDateFilters
                            setid={column.searchValue + "_search"}
                            headers={column.searchValue + "-" + index}
                            key={index} column={column}
                            width={columnWidth - COLUMN_LEFT_RIGHT_MARGIN}
                            removeDate={this.removeDate}
                            translate={this.props.translate}
                            dateSelect={this.dateSelect}
                        />
                    );
                }
                case TABLE_COLUMNS_TYPES.CLEAR_ALL_FILTERS: {
                    let clearIconClass = Object.keys(this.props.filters.columns).length === 0 ? "vismaicon vismaicon-cancel-circle disabled text-center" : "vismaicon vismaicon-cancel-circle text-center";

                    return (
                        <td key="clear_all_search" className="text-center p-0 no-hover"
                            style={{width: "40px"}} id={"clear_all_search_search"}
                            headers={"clear_all_search-" + index}>
                             <span className={clearIconClass}
                                   tabIndex={0}
                                   role="button"
                                   title={this.props.translate("myHistory.clearAllFilters")}
                                   onKeyDown={this.clearAllFilters}
                                   onClick={this.clearAllFilters}/>
                        </td>);
                }
                case TABLE_COLUMNS_TYPES.NONE:
                case TABLE_COLUMNS_TYPES.TASK_STATUS:
                    return (
                        <td key={index} id={column.searchValue + "_search"} headers={column.searchValue + "-" + index}
                        className={"no-hover"}>
                            <span/></td>);
                default: {
                    return (
                        <td key={index} style={{width: columnWidth}} id={column.searchValue + "_search"}
                        className={"no-hover"}>
                            <SearchGroupInput value={column.selectedValue}
                                              ariaLabel={column.header?.label}
                                              onInputChange={this.defaultSearch.bind(null, column.searchValue)}
                                              onClearInput={this.clearSearch.bind(null, column.searchValue)}
                                              translate={this.props.translate}
                            />
                        </td>);
                }
            }
        });
        return (
            <tr>
                {cells}
            </tr>
        );
    }
}

TableSearchRow.propTypes = {
    translate: PropTypes.func, //translation function
    filters: PropTypes.object, //discribes the filters from the store
    columns: PropTypes.array,//array of columns to display
    onSearch: PropTypes.func, //translation function
}

export default TableSearchRow;