import translate from "components/translations/translations.wrapper";
import noInvoice from "components/taskdetails-remake/icons/Pictogram-selected-invoice.svg";
import { BypassCIRSkeletonActions } from "components/taskdetails-remake/panels/attachments/BypassCIR.Skeleton";
import AttachmentsPanelHeader from "components/taskdetails-remake/panels/attachments/detached/AttachmentsPanelHeader.component";

export const AttachmentsPanelUnavailable = ({translate}) => {

    return (
        <div className={"d-flex flex-column detached w-100 h-100"}>
            <div className="mb-3"><AttachmentsPanelHeader /></div>
            <BypassCIRSkeletonActions />
            <div className="d-flex flex-column h-100 flex-grow-1 rounded-4 bg-natural-white icon-128 p-64 m-3 mt-0">
                <img src={new URL(noInvoice, window.location.origin).href}
                     alt={translate("taskDetail.unavailableDocument.title")} />
                <div className="pt-32 font-16 semibold">
                    {translate("taskDetail.unavailableDocument.title")}</div>
                <div>{translate("taskDetail.unavailableDocument.subtitle")}</div>
            </div>
        </div>

    );
};

export default translate(AttachmentsPanelUnavailable);